import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useRoutes, useLocation } from "react-router-dom";
import axios from "axios";
import Themeroutes from "./routes/Router";
import PublicRoutes from "./routes/PublicRoutes";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from 'react-progress-bar-plus';
import 'react-progress-bar-plus/lib/progress-bar.css';
import { GlobalContext } from "./context/Contexts/GlobalLoader";

const App = () => {
  let location = useLocation();
  const { globalState, setGlobalState, globalFunction } = useContext(GlobalContext);
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [userInfo, setUserInfo] = useState();

  const routing = useRoutes(Themeroutes);
  const Publicrouting = useRoutes(PublicRoutes);
  const navigate = useNavigate();

  const authUser = async () => {
    const res = await axios.get('auth-user');
    setUserInfo(res.data.data);
  }
  const isAuthenticated = () => {
    // Replace this with your authentication logic
    return localStorage.getItem("id") !== null;
  };

  useEffect(() => {
    axios.defaults.baseURL = "https://apieclint.beckdemos.com/api/"
    // Set default headers for all requests
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("token");
    // Add request interceptor
    axios.interceptors.request.use(
      (config) => {
        // You can modify the config object here if needed
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Add response interceptor
    axios.interceptors.response.use(
      (response) => {
        // You can modify the response object here if needed
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const requestInterceptor = axios.interceptors.request.use((config) => {
      setLoading(true);
      setProgress(0);
      setGlobalState(true);
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setLoading(false);
        setProgress(100);
        setGlobalState(false);
        return response;
      },
      (error) => {
        setLoading(false);
        setProgress(0);
        setGlobalState(false);
        throw error;
      }
    );

    // authUser();

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [])
  const CheckUserStatus = async () => {
    try {
      const response = await axios.get(
        `check-user-status/${localStorage.getItem('id')}`
      );
      const data = await response.data;
      if (data.status != true) {
        localStorage.clear();
        toast.error("Your account has been expired by admin", {
          position: "top-right",
          id: 1
        });
        setTimeout(() => {
          navigation("/");
        }, 1900);
      }
    } catch (error) {
      // Handle any errors
      console.log(error);
    }
  };

  useEffect(() => {

    CheckUserStatus();
  }, [location]);

  return (
    <div className="dark">
      <Toaster />
      {loading && <ProgressBar percent={progress} className="progress-bar" autoIncrement spinner={false} />}
      {localStorage.getItem("id") ? routing : Publicrouting}
    </div>
  );
};

export default App;
